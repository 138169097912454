<template>
  <section class="full-table section mb-4">
    <div class="rectangle-block-wrapper">
      <div
        class="rectangle-block"
        :class="{
          'mode--blogger': filterParams.customer_kind === 'blogger',
          'mode--commerce': filterParams.customer_kind === 'shop',
        }"
      >
        <slot
          v-bind="{
            pageChangeHandler,
            updateData,
            disableLikedBtn,
            segmentation,
            currentSegment,
            setSegment,
            sampled,
          }"
          name="upper-header"
        ></slot>
        <slot name="post-header-mobile"></slot>
        <div>
          <div v-if="!reviewsLoading">
            <div
              class="mouseover-tooltip only-desktop only-desktop--block"
              id="mouseover-tooltip"
            ></div>
            <div class="plus-tooltip only-desktop only-desktop--block" id="plus-tooltip">
              Все отзывы на блогера
            </div>
            <div class="mobile-tooltip only-mobile only-mobile--block" id="mobile-tooltip">
              Это блогер, который рекламировал блог заказчика рекламы или его товар/услугу
            </div>
            <div class="nothing-found px-5" v-if="!reviews.length">
              <div v-if="bans && bans.length">
                Блогер {{ bans[0].instaname }} занесен в черный список сайта отзывов
                {{ bannedForever && bannedForever.length ? ' навсегда.' : '.' }}
                <span v-if="!bannedForever || !bannedForever.length">
                  Чтобы выйти из черного списка, нужно набрать подряд
                  {{ leftBanReviews || 10 }} положительных отзывов.
                </span>
                <b-button style="font-size: 18px" variant="link" href="/rules/" target="_blank"
                  >Правила сайта отзывов</b-button
                >
              </div>
              <div v-else>По заданным критериям поиска нет результатов</div>
            </div>
            <b-table
              class="reviews-table mt-3"
              ref="rev-table"
              responsive
              striped
              bordered
              v-if="windowWidth >= 1234"
              v-show="reviews.length"
              :fields="fields"
              :items="reviewsItems"
            >
              <template #head()="data">
                <div :class="['align-middle']">
                  {{ data.label }}
                </div>
              </template>

              <template #cell(index)="data">
                <span
                  :title="`Еще ${data.item.reviews_count_advertiser}`"
                  class="one-btn"
                  @click.prevent="toggleMoreByBlogger(data.item.id)"
                  v-if="
                    !data.item.referencedBy &&
                    data.item.reviews_count_advertiser &&
                    filterParams.mode === 'all' &&
                    !moreLoading.includes(data.item.id)
                  "
                  >{{ data.item.showMore ? '–' : '+' }}</span
                >
                <div v-else-if="moreLoading.includes(data.item.id)" class="vertical-align-middle">
                  <b-spinner class="mx-2" style="width: 15px; height: 15px" variant="secondary">
                  </b-spinner>
                </div>
              </template>

              <template #cell(customer)="data">
                <b-button
                  variant="link"
                  class="text-left"
                  style="
                    white-space: nowrap !important;
                    overflow: hidden !important;
                    text-overflow: ellipsis !important;
                    max-width: 100%;
                  "
                  v-b-tooltip="data.value + ', нажмите чтобы скопировать'"
                  v-clipboard:copy="data.value"
                  :title="data.value"
                >
                  {{ data.value }}
                </b-button>
              </template>
              <template #cell(advertiser)="data">
                <b-button
                  variant="link"
                  class="text-left"
                  style="
                    white-space: nowrap !important;
                    overflow: hidden !important;
                    text-overflow: ellipsis !important;
                    max-width: 100%;
                  "
                  v-b-tooltip="data.value + ', нажмите чтобы скопировать'"
                  v-clipboard:copy="data.value"
                  :title="data.value"
                  >{{ data.value }}</b-button
                >
                <span
                  style="color: red"
                  class=""
                  v-if="
                    !data.item.accepted &&
                    data.item.deletion_status === 1 &&
                    filterParams &&
                    filterParams.mode === 'me'
                  "
                  >Просит удалить отзыв</span
                >
                <img
                  @click="$router.push({ name: 'pr-agency-apply' })"
                  v-if="
                    data.item.advertiser_blogger && data.item.advertiser_blogger.pr_agency_member
                  "
                  style="right: 5px; bottom: 5px; cursor: pointer"
                  class="position-absolute"
                  src="@main/assets/img/svg/firemark.svg"
                  alt=""
                />
              </template>
              <template #cell(stat_btn)="data">
                <div v-if="data.item.advertiser_blogger">
                  <b-button
                    class="text-left"
                    @click="() => $emit('show-stat', String(data.item.advertiser))"
                    variant="link"
                    >Статистика</b-button
                  >
                  <br />
                  <small class="d-inline-block text-truncate stat-col-blogger">{{
                    data.item.advertiser
                  }}</small>
                  <a
                    class="d-none"
                    :href="`https://easyprbot.com/render/#/bloggers/${data.item.advertiser}`"
                    :alt="`Блогер @${data.item.advertiser}`"
                  ></a>
                </div>
                <div
                  v-else-if="userCart && !userCart.reviews.includes(data.item.id)"
                  class="d-none hidden-stat-btn"
                >
                  <b-button
                    style="
                      line-height: 16x !important;
                      white-space: break-spaces !important;
                      line-break: normal !important;
                    "
                    class="text-left"
                    @click="handleGatheringRequest(data.item)"
                    variant="link"
                    >Запросить</b-button
                  >
                  <small class="d-inline-block text-truncate stat-col-blogger">{{
                    data.item.advertiser
                  }}</small>
                </div>
                <div v-else-if="userCart">
                  <small class="d-inline-block stat-col-blogger">В корзине</small>
                  <b-button
                    style="
                      line-height: 16x !important;
                      line-break: normal !important;
                      white-space: break-spaces !important;
                    "
                    @click="() => removeReviewFromCart(data.item.id)"
                    class="text-left"
                    variant="link"
                    >Удалить</b-button
                  >
                </div>

                <!--div class="" style="max-width: 110px" v-else>
                 <div>
                    <b-button
                      v-if="userCart.reviews.includes(data.item.id)"
                      style="line-break: everywhere !important; white-space: break-spaces !important"
                      :to="{ path: '/promobot/orders/cart/' }"
                      class="text-left"
                      variant="link"
                      >Запрос отправлен</b-button
                    >
                    <b-button
                      v-else
                      @click="handleGatheringRequest(data.item)"
                      style="line-break: everywhere !important"
                      class="text-left"
                      variant="link"
                      >Отправить запрос</b-button
                    >
                 </div>

                </!--div-->
              </template>
              <template #cell(rate)="data">
                {{ data.value }}
                <img
                  v-if="data.value"
                  class="rev_star"
                  src="@main/assets/img/svg/star.svg"
                  alt=""
                />
              </template>

              <template #cell(text_display)="row">
                <b-button v-if="row.item.text_display" variant="link" @click="row.toggleDetails">{{
                  row.detailsShowing ? 'Закрыть' : 'Открыть'
                }}</b-button>
              </template>

              <template #cell(customer_tags)="data">
                <div class="review-tags" v-b-tooltip.hover :title="data.value">
                  <span v-line-clamp:20="2">{{ data.value }}</span>
                </div>
              </template>

              <template #cell(date)="data">
                <div class="d-flex flex-row justify-content-center">
                  <div>
                    <p style="font-size: 10pt !important; margin-bottom: 1px" class="text-grey">
                      № {{ data.item.id }}
                    </p>
                    {{ data.value }}
                  </div>
                </div>
              </template>

              <template #cell(moderation_status)="data">
                <div style="min-width: 110px">
                  <div v-if="typeof data.value === 'object'">
                    <span class="mr-2" v-html="data.value.html"></span>
                    <b-button
                      v-if="data.value.button"
                      @click="() => data.value.button.handler()"
                      variant="link"
                      >{{ data.value.button.text }}</b-button
                    >
                  </div>
                  <div v-else v-html="data.value"></div>
                </div>
              </template>
              <template #cell(delete_review)="data">
                <div
                  @click="del(reviewsItems.find((val) => val.id === data.item.id))"
                  class="
                    btn
                    d-flex
                    flex-row
                    align-items-center
                    text-align-center
                    justify-content-center
                  "
                >
                  <svg class="icon-close-btn icon-hover-close">
                    <use xlink:href="@main/assets/sprite.svg#close-btn"></use>
                  </svg>
                </div>
              </template>
              <template #row-details="row">{{ row.item.text_display }} </template>
              <template #cell(liked)="data">
                <div
                  v-if="!loading[`review-like-${data.item.id}`]"
                  @click="like(data.item)"
                  style="cursor: pointer; min-width: 20px !important"
                  class="d-flex flex-column align-items-center"
                >
                  <template v-if="!data.item.liked_by_viewer">
                    <img
                      class="rev-lk like-btn-gr"
                      src="@main/assets/img/svg/like-gray-outline.svg"
                      alt=""
                    />
                    <img
                      class="rev-lk d-none like-btn-hov"
                      src="@main/assets/img/svg/like-outline.svg"
                      alt=""
                    />
                  </template>
                  <img class="rev-lk" v-else src="@main/assets/img/svg/like.svg" alt="" />
                  <span v-if="data.value" class="mx-auto text-center fs-12 gray-text">{{
                    data.value
                  }}</span>
                </div>
                <b-spinner
                  v-else
                  class="mx-auto"
                  style="width: 20px; height: 20px"
                  variant="secondary"
                >
                </b-spinner>
              </template>
              <template #cell(deletion_status)="data">
                <div>
                  <div
                    @click="sendDeletionRequest(data.item.id)"
                    v-if="!data.value"
                    class="w-100 btn"
                  >
                    <img
                      class="mx-auto my-auto"
                      style="width: 16px; height: 16px"
                      src="@main/assets/img/svg/gray-cross.svg"
                      alt=""
                    />
                  </div>
                  <div v-else>{{ data.value }}</div>
                </div>
              </template>
            </b-table>
            <div :key="disclosed" class="reviews-mobile row p-0 rev-mb" v-else>
              <telegram-mobile-review
                v-bind="{ ...$attrs, ...$props }"
                v-for="review in reviewsItems"
                :fieldOrder="fieldOrder"
                :disclosed="disclosed"
                :ref="`mobileReview${review.id}`"
                :review="review"
                :moreLoading="moreLoading"
                :excludedFields="excludedFields"
                :filterParams="filterParams"
                :key="`${review.referencedBy}-${review.id}-H871dc`"
                @show-more="toggleMoreByBlogger(review.id)"
                @hide-more="toggleMoreByBlogger(review.id)"
                @del="del"
                @edit="edit"
                @delete-request="sendDeletionRequest"
                @like="like"
                @show-stat="(event) => $emit('show-stat', event)"
              ></telegram-mobile-review>
            </div>
            <!--div
            
              v-if="reviews && reviews.length"
              style="background-color: #fffce3; border-bottom: 1px solid #dee2e6"
              class="d-none d-xl-flex justify-content-center align-items-center pb-3 flex-wrap"
            >
              <p class="mt-3">
                <span style="font-weight: 500">Снизьте цену за подписчика до 72%</span> и проверьте
                окупаемость рекламы ещё на этапе выбора блогеров вместе с Трекером рекламы
              </p>
              <b-button href="/tracker/" class="mt-3 ml-3" variant="outline-default"
                >Открыть Трекер рекламы</b-button
              >
            </!--div-->
            <div
              v-if="
                totalPages > 1 &&
                reviews &&
                !reviews.length <= 0 &&
                filterParams.page != totalPages &&
                showMoreTimes + filterParams.page != totalPages
              "
              class="show-more"
              @click="!showMoreLoader ? showMore() : null"
            >
              <template v-if="!showMoreLoader">Показать еще</template>
              <loader v-else />
            </div>
          </div>
          <loader v-else />
          <notification-window />
        </div>
        <Pagination
          v-if="totalPages > 1"
          :current="filterParams.page"
          :total="totalPages"
          @page-change="pageChangeHandler"
        />
      </div>
    </div>
    <template v-for="review in reviewsItems">
      <div
        style="display: none"
        :key="`${review.id}=kJnh7hd`"
        vocab="https://schema.org/"
        typeof="Review"
      >
        <div property="itemReviewed" typeof="Product">
          <img
            property="image"
            src="https://easyprbot.com/static/web/img/review.png"
            :alt="`Отзыв на ${review.advertiser}`"
          />
          <div property="aggregateRating" typeof="AggregateRating">
            <span property="ratingValue">{{ (review.rate / 2).toFixed(1) }}</span> звезд из
            <span property="reviewCount">1</span> (этого) отзыва
          </div>
          <span property="name">Реклама у блогера {{ review.instaname }}</span>
          <span property="identifier">{{ review.id }}</span>
        </div>
        <span property="reviewRating" typeof="Rating">
          <span property="ratingValue">{{ (review.rate / 2).toFixed(1) }}</span>
        </span>
        stars -
        <b
          ><span property="name">Отзыв на блогера {{ review.advertiser }}</span>
        </b>
        <span property="author" typeof="Person">
          <span property="name">{{ review.customer }}</span>
        </span>
        <span property="reviewBody">{{ review.text_display }}</span>
        <span property="aggregateRating"></span>
        <div property="publisher" typeof="Organization">
          <meta property="name" content="@Easyprbot" />
        </div>
      </div>
    </template>
  </section>
</template>

<script>
import clone from 'clone';
import { isMobile } from 'mobile-device-detect';
import { mapMutations, mapActions } from 'vuex';
import wrappers from '@main/func/wrappers';
import { RequiresAuth } from '@main/func/pipeline';
import Loader from '@main/components/Loader.vue';
import Pagination from '@main/components/Pagination.vue';
import AlertGreyIcon from '@main/assets/img/svg/allert_grey.svg';
import NotificationWindow from '@main/components/notifications/NotificationWindow.vue';
import TelegramMobileReview from '@main/components/reviews_list/TelegramMobileReview.vue';
import objects from '@main/func/objects';
import reviewsApi from '../../api/reviews';
import DeleteReviewPreview from './popups/DeleteReviewPreview.vue';
import TableFieldManageMixinVue from '../../mixins/tables/TableFieldManageMixin.vue';
import TelegramReviewTableMixin from './mixins/TelegramReviewTableMixin.vue';

const cellClass = ['align-middle', 'review__table--cell'];
const thClass = ['align-middle', 'font-weight-bold--revtable'];
const defField = { class: cellClass, thClass };

export default {
  components: {
    Loader,
    TelegramMobileReview,
    NotificationWindow,
    Pagination,
  },
  model: {
    prop: 'filterParams',
    event: 'input',
  },
  props: [
    'blogger',
    'kind',
    'filterParams',
    'excludedFields',
    'disableLikedBtn',
    'queryFields',
    'disclosed',
    'reviewData',
    'segmentationFunc',
    'replaceFields',
    'sample',
    'sortFunc',
    'mapFunc',
    'fieldOrder',
  ],
  conditionArguments: ['filterParams', 'reviewsItems'],
  mixins: [TableFieldManageMixinVue, TelegramReviewTableMixin],
  provide() {
    return {
      reviewsFields: this.fields,
    };
  },
  data() {
    return {
      showMoreLoader: false,
      reviews: [],
      isMobile,
      showMoreTimes: 0,
      lastRequestId: null,
      totalPages: 0,
      bans: [],
      moreLoading: [],
      reviewsLoading: true,
      currentSegment: null,
      sampled: false,
      deletionStatusMapping: [
        {
          text: 'Запрос отправлен',
        },
        {
          text: 'Заказчик отклонил запрос',
        },
        {
          text: 'Отзыв удален',
        },
      ],
    };
  },
  computed: {
    tableMode() {
      return this.filterParams.mode;
    },
    fields() {
      let fieldArray = [
        {
          modes: ['all'],
          label: ' ',
          key: 'index',
          ...defField,
          class: [...cellClass, 'index-col'],
        },
        {
          label: 'Дата рекламы',
          key: 'date',
          formatter: this.$options.filters.formatDate,
          ...defField,
          class: ['align-middle', 'review__table--cell', 'date__cell'],
        },
        {
          label: 'Аккаунт заказчика',
          key: 'customer',
          ...defField,
          class: [...cellClass, this.filterParams.customer_kind === 'shop' ? 'cus__cell_comm' : ''],
        },
        {
          label: 'Аккаунт исполнителя',
          key: 'advertiser',
          ...defField,
          class: [
            ...cellClass,
            this.filterParams.customer_kind === 'shop' ? 'adv__cell_comm' : '',
            'position-relative',
          ],
        },
        {
          label: 'Реклама окупилась',
          key: 'paid_off',
          formatter: this.formatPaidOff,
          ...defField,
          condition({ filterParams, reviewsItems }) {
            return (
              (filterParams.customer_kind === 'shop' || filterParams.mode === 'blogger') &&
              reviewsItems.some((val) => typeof val.paid_off !== 'undefined')
            );
          },
        },
        {
          label: 'Цена за подписчика',
          key: 'price_per_one',
          formatter: this.formatPPO,
          ...defField,
          class: [...cellClass, 'ppo__cell'],
        },
        {
          label: 'Приход',
          key: 'arrival',
          ...defField,
          formatter: this.formatArrival,
        },
        {
          label: 'Цена за рекламу',
          key: 'price',
          formatter: this.formatPrice,
          ...defField,
        },
        {
          label: 'Охваты из рекламы',
          key: 'largest_reach',
          formatter: this.formatLargestReach,
          ...defField,
          class: [...cellClass, 'cov__cell'],
        },
        {
          label: 'Статистика блогера',
          key: 'stat_btn',
          ...defField,
          class: [...cellClass, 'stat__cell'],
          modes: ['all'],
        },
        { label: 'Оценка', key: 'rate', ...defField },
        {
          label: 'Формат рекламы',
          key: 'ad_type',
          formatter: this.formatAdType,
          ...defField,
          class: [...cellClass, 'ad_type__cell'],
        },

        {
          label: 'Товар или услуга',
          key: 'item',
          ...defField,
          class: [...cellClass, 'tag__cell'],
          condition({ filterParams, reviewsItems }) {
            return (
              (filterParams.customer_kind === 'shop' || filterParams.mode === 'blogger') &&
              reviewsItems.some((val) => val.item)
            );
          },
        },
        {
          label: 'Темы аккаунта',
          key: 'customer_tags',
          formatter: this.formatCustomerTags,
          condition({ filterParams }) {
            return filterParams.customer_kind === 'blogger' || filterParams.mode === 'blogger';
          },
          ...defField,
          class: [...cellClass, 'tag__cell'],
        },
        {
          label: 'Статус',
          key: 'moderation_status',
          ...defField,
          formatter:
            this.filterParams.mode === 'blogger' ? this.formatStatusBlogger : this.formatStatus,
          modes: ['me', 'blogger'],
          class: [...cellClass, 'status__cell'],
        },
        { label: 'Текст отзыва', key: 'text_display', ...defField },
        { label: 'Удалить', key: 'delete_review', ...defField, modes: ['me'] },
        {
          label: ' ',
          key: 'liked',
          ...defField,
          condition({ filterParams }) {
            return filterParams.customer_kind === 'blogger' || filterParams.mode === 'blogger';
          },
        },
      ];
      fieldArray = this.processFields(fieldArray);
      return fieldArray;
    },
    reviewsItems() {
      if (this.segmentation) {
        if (this.currentSegment) {
          return this.segmentation.filter((val) => val.name === this.currentSegment)[0].items;
        }
        return this.segmentation[0].items;
      }
      return this.reviews.map((review) => {
        if (review.rate && review.rate <= 6) {
          return { ...review, text_display: '' };
        }
        return review;
      });
    },
    segmentation() {
      if (!this.segmentationFunc) return;
      return this.segmentationFunc(this.reviews);
    },
    bannedForever() {
      if (!this.bans) {
        return;
      }
      return this.bans.filter((val) => val.forever);
    },
    leftBanReviews() {
      if (!this.bans) {
        return;
      }
      const ban = this.bans.filter((val) => !val.forever)[0];
      if (ban.positive_reviews >= 10) {
        return;
      }
      return 10 - ban.positive_reviews;
    },
  },
  methods: {
    async removeReviewFromCart(id) {
      await this.updateUserCart({
        ...this.userCart,
        reviews: this.lodash.difference(this.userCart.reviews, [id]),
      });
    },
  },
  async mounted() {
    await this.updateData();
    if (this.segmentation) {
      this.currentSegment = this.segmentation[0].name;
    }
    if (this.$route.query.mcall) {
      const callMethods = JSON.parse(this.$route.query.mcall);
      callMethods.forEach((m) => {
        this[m.name](...m.args);
      });
    }
  },
};
</script>

<style scoped lang="scss">
.stat-col-blogger {
  color: #6a7b83;
  max-width: 80px;
}

.reviews-table >>> td {
  min-height: 63px !important;
}

.review-table >>> .align-middle {
  font-weight: 500px !important;
}

.show-more {
  cursor: pointer;
  width: 100%;
  border-bottom: 1px solid #d5dfe4;
  text-align: center;
  padding: 20px;
  font-size: 14px;
  color: #6a7b83;
}

.reviews-table >>> .b-table-details {
  background-color: #fffce3;
}

.reviews-table {
  margin-bottom: 0px !important;
}

::v-deep .table {
  border-right: none;
  border-left: none;
}

.review-tags {
  max-height: 2em;
  line-height: 1em;
  word-break: break-word;
}
.rev-mb {
  margin-right: 0px !important;
  margin-left: 0px !important;
}
.coverage__th-rev {
  min-width: 160px !important;
}
.icon-hover-close:hover {
  fill: red;
}

@media (max-width: 350px) {
  .short-btn {
    padding: 0.375rem 0.35rem;
  }
}
.leave_rev-block {
  // min-height: 300px;
  height: 300px !important;
  max-height: 300px !important;
  flex-direction: column;
}
.fillstat-btn {
  widows: 100%;
  margin: 1px;
  margin-top: 40px;
}
.rev-title {
  margin-right: 14px;
}

@media (min-width: 768px) {
  .btn-rev {
    width: 97px;
    margin-right: 15px;
  }
}

@media (max-width: 768px) {
  .btn-rev {
    border-radius: 0;
  }
  .btn-rev:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .btn-rev:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
}
.button-fill-stat__mobile {
  display: block;
  width: 100%;
  border-bottom: 1px solid #d5dfe4;
  background-color: #fffce3;
}
@media (min-width: 400px) {
  .row-btns__rev {
    max-width: 300px !important;
  }
}
::v-deep .table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #dee2e6 !important;
}
::v-deep .table-bordered td:first-child {
  border-left: none !important;
}
::v-deep .table-bordered td:last-child {
  border-right: none !important;
}

::v-deep .table-bordered th:last-child {
  border-right: none !important;
}
::v-deep .table-bordered th:first-child {
  border-left: none !important;
}
::v-deep .table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #dee2e6;
}
</style>
