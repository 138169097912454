<script>
import { RequiresAuth } from '@main/func/pipeline';
import { mapState, mapMutations, mapActions } from 'vuex';
import reviewsApi from '@main/api/reviews';
import clone from 'clone';
import { isMobile } from 'mobile-device-detect';
import wrappers from '@main/func/wrappers';
import Loader from '@main/components/Loader.vue';
import Pagination from '@main/components/Pagination.vue';
import AlertGreyIcon from '@main/assets/img/svg/allert_grey.svg';
import NotificationWindow from '@main/components/notifications/NotificationWindow.vue';
import MobileReview from '@main/components/reviews_list/MobileReview.vue';
import objects from '@main/func/objects';
import DeleteReviewPreview from '@main/components/reviews_list/popups/DeleteReviewPreview.vue';
import TableFieldManageMixinVue from '@main/mixins/tables/TableFieldManageMixin.vue';
import EditReviewScreens from '@main/components/reviews_list/popups/EditReviewScreens.vue';

export default {
  computed: {
    ...mapState(['currentUser', 'windowWidth', 'userCart', 'reviewsOptions']),
    ...mapState('reviewsModeration', ['refuseReasons']),
    ...mapState(['tags', 'loading']),
  },
  methods: {
    ...mapMutations('notifications', ['showMessage']),
    ...mapMutations(['setUserData']),
    ...mapActions(['updateUserCart']),
    ...mapActions('notifications', ['setWindow']),
    getParams() {
      const params = this.lodash.cloneDeep(this.filterParams);
      params.format = 'json';
      params.type = params.ad_type;
      if (params.customer_kind === 'blogger') delete params.item_q;
      if (params.customer_kind === 'shop') delete params.tags;
      delete params.ad_type;
      if (this.queryFields) {
        return { ...params, fields: this.queryFields };
      }
      return params;
    },
    async updateData({ showMore } = {}) {
      if (!showMore) {
        this.reviewsLoading = true;
      }
      this.bans = null;
      const randomRequestId = Math.floor(Math.random() * 1000000);
      this.lastRequestId = randomRequestId;
      let params = this.getParams();
      if (this.queryFields) {
        params = { ...params, ...{ fields: this.queryFields } };
      }
      if (!showMore) {
        this.reviews = [];
        this.page = 1;
      }
      let response;
      try {
        if (!showMore) {
          response = await reviewsApi.telegram.list(params);
        } else {
          response = await reviewsApi.telegram.list({
            ...params,
            page: (params.page || 1) + this.showMoreTimes,
          });
        }
      } catch (e) {
        response = { results: [] };
      }
      if (response && randomRequestId === this.lastRequestId) {
        this.totalPages = response.total_pages;
        let { results } = response;
        if (!results.length && this.sample) {
          results = await this.sample();
          this.sampled = true;
        }
        results = results.map((x) => ({
          ...x,
          showMore: false,
          price_per_one: x.price / x.arrival,
        }));
        if (this.mapFunc) {
          results = results.map(this.mapFunc);
        }
        this.reviews.push(...(this.sortFunc ? this.sortFunc(results) : results));
        if ((!results || !results.length) && params.executor) {
          const bans = await reviewsApi.bans.list({
            instaname: params.executor,
            fields: 'instaname,banned_date,forever,is_banned,positive_reviews',
            is_banned: true,
            ordering: '-banned_date',
          });
          this.bans = bans.results;
        }
      }
      this.reviewsLoading = false;
    },
    editWindow(review) {
      this.setWindow({
        topLess: false,
        title: 'Загрузите скриншоты',
        contentComponent: { component: EditReviewScreens, attrs: { reviewData: review } },
        closeCallback: () => {
          this.updateData();
        },
      });
    },
    showReason(review) {
      const reasons = this.refuseReasons
        .filter((reason) => {
          return review.refuse_reasons.includes(reason.id);
        })
        .map((reason) => reason.text)
        .join('\n\n');
      this.setWindow({
        topLess: false,
        title: `Причина отклонения отзыва №${review.id}`,
        html: `${reasons || 'Причины не указаны'}`,
        buttons:
          this.filterParams.mode === 'blogger'
            ? []
            : [
                {
                  text: 'Редактировать и отправить отзыв заново',
                  attrs: { variant: 'outline-default' },
                  handler: () => {
                    this.edit(review.id);
                    this.setWindow(null);
                  },
                },
              ],
      });
    },
    async handleGatheringRequest(review) {
      const handler = new RequiresAuth();
      handler.handle(() => {
        if (review.advertiser_blogger) {
          this.$emit('show-stat', String(review.advertiser));
          setTimeout(() => {
            document.getElementById('blogger-nopr').scrollIntoView({ behavior: 'smooth' });
          }, 600);
          return;
        }
        this.updateUserCart({
          ...this.currentUser.preorders,
          reviews: this.userCart.reviews.concat(review.id),
        });
      });
    },
    async showMore() {
      try {
        this.showMoreLoader = true;
        this.showMoreTimes += 1;
        await this.updateData({ showMore: true });
      } catch (e) {
        this.showMoreLoader = false;
        if (e.response.status >= 500) {
          this.setWindow({
            text: 'Произошла ошибка при загрузке отзывов. Перезагрузите страницу и попробуйте еще раз или обратитесь в техническую поддержку!',
            iconPath: AlertGreyIcon,
          });
        }
      } finally {
        this.showMoreLoader = false;
      }
    },
    getInstagramUrl(i) {
      return `https://www.instagram.com/${i}/`;
    },
    async toggleMoreByBlogger(reviewId) {
      this.moreLoading.push(reviewId);
      const review = this.reviews.filter((x) => x.id === reviewId)[0];
      if (!review.showMore) {
        const params = {
          max_count: 20,
          executor: review.advertiser,
          exclude__id: review.id,
          customer_kind: review.customer_kind,
        };
        const response = await reviewsApi.telegram.list(params);
        if (response) {
          review.showMore = true;
          this.$set(review, '_rowVariant', 'yellow');
          let { results } = response;
          results = results
            .map((x) => ({ ...x, showMore: false, referencedBy: review.id, _rowVariant: 'yellow' }))
            .filter((x) => x.id !== review.id);
          this.reviews.splice(this.reviews.indexOf(review) + 1, 0, ...results);
        }
      } else {
        this.$set(review, '_rowVariant', null);
        review.showMore = false;
        this.reviews = this.reviews.filter((x) => x.referencedBy !== review.id);
      }
      this.moreLoading.splice(this.moreLoading.indexOf(reviewId), 1);
    },
    toggleAllText() {
      console.log(this.$refs);
    },
    setSegment(name) {
      this.currentSegment = name;
    },
    async sendDeletionRequest(id) {
      this.setWindow({
        topLess: false,
        titleAttrs: { class: 'font-weight-bold' },
        title: 'Попросить удалить отзыв',
        text: 'Вы точно хотите отправить заказчику сообщение с просьбой об удалении отзыва?',
        buttons: [
          {
            attrs: { variant: 'primary', style: 'align-self: start', class: 'w-25' },
            text: 'Да',
            handler: async () => {
              await reviewsApi.telegram.deletionRequest(id);
              await this.updateData();
              this.setWindow(null);
            },
          },
          {
            attrs: {
              variant: 'outline-default',
              class: 'ml-auto w-25',
            },
            text: 'Нет',
            handler: () => {
              this.setWindow(null);
            },
          },
        ],
        footer: {
          attrs: {
            class: 'w-100 justify-content-between',
          },
        },
      });
    },
    edit(id) {
      this.$router.push({ name: 'edit-review', params: { id } });
    },
    async del(review) {
      if (!(typeof review === 'object')) {
        review = this.reviewsItems.find((val) => val.id === review);
      }
      await this.deleteReview(review);
    },
    async deleteReview(review) {
      if (typeof review !== 'object') {
        review = await reviewsApi.telegram.get(review);
      }
      const { id } = review;
      this.setWindow({
        title: 'Посмотреть и удалить отзыв',
        topLess: false,
        contentClass: 'p-1',
        contentComponent: {
          component: DeleteReviewPreview,
          attrs: {
            review,
          },
        },
        wrapClass: 'modal-md',
        footer: {
          attrs: {
            class: 'w-100 justify-content-between',
          },
        },
        buttonClass: 'mx-3 mt-3',
        buttons: [
          {
            text: 'Не удалять',
            attrs: {
              variant: 'outline-default',
            },
            handler: async () => {
              await reviewsApi.telegram.deleteRefuse(id);
              this.setWindow(null);
            },
          },
          {
            text: 'Удалить отзыв',
            attrs: {
              variant: 'primary',
            },
            handler: () => {
              this.setWindow({
                footer: {
                  attrs: {
                    class: 'w-100 justify-content-between',
                  },
                },
                buttonClass: 'm-0 mt-3',
                title: 'Удалить отзыв',
                topLess: false,
                html: `<p class="fs-16">Вы точно хотите удалить отзыв №${id}? <br> Действие необратимо.</p>`,
                buttons: [
                  {
                    text: 'Назад',
                    attrs: {
                      variant: 'outline-default',
                    },
                    handler: () => {
                      this.setWindow(null);
                    },
                  },
                  {
                    text: 'Удалить',
                    attrs: { variant: 'primary' },
                    handler: async () => {
                      try {
                        await reviewsApi.telegram.delete(id);
                        this.setWindow({
                          title: 'Удалить отзыв',
                          html: `<p class="fs-16 align-self-start">Отзыв №${id} удален.</p>`,
                          topLess: false,
                          contentClass: 'text-left p-4 mw-25',
                          wrapClass: 'mw-25 modal-md',
                          buttonClass: 'm-0 mt-3',
                          footer: {
                            attrs: {
                              class: 'justify-content-start',
                              style: 'min-width: 250px !important',
                            },
                          },
                          buttons: [
                            {
                              text: 'Закрыть',
                              attrs: { variant: 'outline-default' },
                              handler: () => {
                                this.setWindow(null);
                              },
                            },
                          ],
                        });
                        const idx = this.reviews.findIndex((val) => val.id === id);
                        if (idx > -1) {
                          this.reviews.splice(idx, 1);
                        }
                      } catch (e) {
                        console.log(e);
                        this.showMessage({
                          title: 'Ошибка!',
                          message:
                            'Не удалось удалить отзыв, возможно он уже был удален! Перезагрузите страницу и попробуйте позже.',
                          icon: 2,
                        });
                        this.setWindow(null);
                      }
                    },
                  },
                ],
              });
            },
          },
        ],
      });
    },
    async pageChangeHandler(page) {
      this.showMoreTimes = 0;
      await this.setFilterParams({ page });
      await this.updateData();
    },
    setFilterParams(data) {
      this.$emit('input', { ...this.filterParams, ...data });
    },
    setLike(review) {
      wrappers.triggersLoading(() => {
        reviewsApi.telegram.like(review.id, this.getParams()).then((response) => {
          this.setUserData({
            liked_reviews: this.currentUser.liked_reviews + (review.liked_by_viewer ? -1 : 1),
          });
          this.$set(
            this.reviews,
            this.reviews.indexOf(this.reviews.find((val) => val.id === review.id)),
            { ...review, ...response, price_per_one: response.price / response.arrival }
          );
        });
      }, `review-like-${review.id}`);
    },
    like(review) {
      const handler = new RequiresAuth();
      handler.handle(() => {
        this.setLike(review);
      });
    },
    formatPaidOff(val) {
      return val ? 'Да' : 'Нет';
    },
    formatArrival(val) {
      if (val) return Number(val).toLocaleString().replace(/,/g, ' ');
    },
    formatLargestReach(val) {
      if (val) return Number(val).toLocaleString().replace(/,/g, ' ');
    },
    formatAdType(value, key, review) {
      return [
        // eslint-disable-next-line prefer-template
        'Сторис' +
          (review.stories_count
            ? ` ${review.stories_count * 15}${review.stories_count >= 6 ? '+' : ''} сек.`
            : ''),
        'Фото-пост',
        'Видео-пост',
        'Сторис + пост',
        'Гив',
      ][value - 1];
    },
    formatCustomerTags(value) {
      if (!value) {
        return undefined;
      }
      return value.map((tag) => tag.name).join(', ');
    },
    formatPPO(value, key, review) {
      const textParts = [];
      if (review.price_meta) {
        if (review.price_meta === 'together') textParts.push('ВП');
        if (review.price_meta === 'barter') textParts.push('Бартер');
      } else if (review.price) {
        if (!review.price_per_one) {
          review.price_per_one = review.price / review.arrival;
        }
        textParts.push(String(parseFloat(review.price_per_one.toFixed(1))).replace('.', ','));
        textParts.push('₽');
      }
      return textParts.join(' ');
    },
    formatPrice(value, key, review) {
      let textParts = [];
      if (review.price_meta === 'together') textParts.push('ВП');
      if (review.price_meta === 'barter') textParts.push('Бартер');
      if (review.price_meta && review.price) textParts.push('+\n');
      if (review.price) {
        textParts = textParts.concat([
          Number(review.price).toLocaleString().replace(/,/g, ' '),
          '₽',
        ]);
      }
      return textParts.join(' ');
    },
    formatReasons(value, key, item) {
      const reasons = this.refuseReasons.filter((reason) => {
        return item.refuse_reasons.includes(reason.id);
      });
      const reasonText = reasons.map((reason) => reason.title).join(', ');
      return reasonText;
    },
    formatStatus(value, key, item) {
      if (this.reviewsOptions.groups.ACCEPTED_STATUSES.includes(value)) {
        return `<span style='color: #33CC74'>Опубликован${
          item.show_text ? '' : ', без текста'
        }</span>`;
      }
      if (this.reviewsOptions.groups.REFUSED_STATUSES.includes(value)) {
        return {
          html: `<span style='color: red'>Отклонен</span>`,
          button: {
            text: 'Причины',
            handler: () => {
              console.log('awdawd');
              this.showReason(item);
            },
          },
        };
      }
      if (this.reviewsOptions.groups.ON_MODERATION_STATUSES.includes(value)) {
        return 'На модерации';
      }
    },
    formatStatusBlogger(value, key, item) {
      if (this.reviewsOptions.groups.ACCEPTED_STATUSES.includes(value)) {
        return `<span style='color: #33CC74'>Опубликован${item.show_text ? '' : ' без текста'}${
          item.moderation_status === 4 ? ', с вашей помощью' : ''
        }${item.sent_to_chats ? ', отправлен в чаты' : ', ожидает отправку в чаты'}</span>`;
      }
      if (this.reviewsOptions.groups.REFUSED_STATUSES.includes(value)) {
        const sc = this.refuseReasons
          .filter((val) => item.refuse_reasons && item.refuse_reasons.includes(val.id))
          .find((val) => val.key === 'sc');
        return {
          html: `<span style='color: ${sc ? 'red' : ''}'>Отклонен${
            sc ? ', из-за скриншотов' : ', нельзя исправить'
          }</span>`,
          button: sc ? { text: 'Исправить', handler: () => this.editWindow(item) } : null,
        };
      }
      if (this.reviewsOptions.groups.ON_MODERATION_STATUSES.includes(value)) {
        return 'На модерации';
      }
    },
  },
};
</script>
